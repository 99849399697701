
import { defineComponent, PropType } from 'vue'
import { GChart } from 'vue-google-charts'

export default defineComponent({
  components: { GChart },
  props: {
    sdgNumber: {
      type: String as PropType<string>,
      required: true,
    },
    sdgColor: {
      type: Array as PropType<string[]>,
      required: true,
    },
    sdgsPrimaryCount: {
      type: Number as PropType<number>,
      required: true,
    },
    sdgsSecondaryCount: {
      type: Number as PropType<number>,
      required: true,
    },
    max: {
      type: Number as PropType<number>,
      required: true,
    },
  },
})
